.navbarCM {
  background-color: var(--cor-fundo-navbar-CM);
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  /* width: 100%; */
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  justify-content: space-evenly;
  /* align-content: space-around; */
  justify-content: center;
  margin: 23px 0px 0px;
}

.navbarColumn1 {
  background-color: #ffffff;
  border-radius: 0 0 99px 0;
  /* box-shadow: 0 1px 0 0 red; */
  height: 100%;
  font-size: 1.75rem;
  display: flex;
}
.navbarColumn1 a {
  text-decoration: none;
}
.cardLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
  margin: 0 auto;
}
.navLogoCM {
  /* margin: 5px; */
  /* width: 100px; */
  width: 100px;
  height: auto;
  /* margin-right: 8px; */
}
.navNameCM {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--cor-nome-portal);
  width: 220px;
  justify-content: space-evenly;
}
.titleNavbarCM {
  /* margin: 0px; */
  margin: 0 0 3px 0px;
  width: 272px;
  justify-content: center;
  text-align: center;
}
h3.titleNavbarCM {
  font-weight: 700;
}
h5.titleNavbarCM {
  font-weight: 600;
}
hr:not([size]) {
  height: 1px;
  width: 56%;
  background-color: var(--cor-line-name-portal-CM);
  border: 15px;
  opacity: 1;
  margin: 0;
}

.navbarColumn2 {
  background: #fff;
}
.subColumn {
  background-color: var(--cor-fundo-navbar-CM);
  display: flex;
  align-content: center;
  flex-direction: column;
  border-radius: 110px 0px 0px 0px;
  align-items: center;
}
.cardSearch {
  display: flex;
  width: 100%;
}
.inputSearch {
  max-width: 400px;
  width: 50%;
  height: 28%;
  padding: 10px;
  margin: 0px;
  border: 1px solid var(--cor-fundo-input-buscar-navbar-CM);
  background-color: var(--cor-fundo-input-buscar-navbar-CM);
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.inputSearch::placeholder {
  color: var(--cor-icones-acessibilidade-CM);
  width: 200;
}
.inputSearch:focus {
  outline: none !important;
  box-shadow: 0 0 0px #4e4a4a;
  transition: 0.8s;
}
.buttonSearch {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: var(--cor-fundo-input-buscar-navbar-CM);
  /* padding: 10px; */
  border: 0px;
}
.svgSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
}

.groupAcessibilidade {
  display: flex;
  flex-direction: row;
  padding: 29px 0px 29px 0px;
  justify-content: center;
  width: 880px;
  align-items: center;
}
.cardButtonAcess {
  display: flex;
}
.iconeAcessibilidade {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--cor-fundo-accessibility-navbar-CM);
  width: 35px;
  height: 35px;
  margin: 0;
  border-radius: 35px;
  margin-left: 9px;
  max-width: 957px;
  box-shadow: rgb(99 99 99 / 81%) 0px 2px 8px 0px;
}
.iconeAcessibilidade svg {
  width: 16px;
  fill: var(--cor-icones-acessibilidade-CM);
}
.iconeAcessibilidade img {
  width: 16px;
}
.contrastSvg {
  /* path */
  fill: var(--cor-icones-acessibilidade-CM);
}
.groupMenu {
  display: flex;
  border-radius: 234px;
}

/* navbar */
.arrow-left {
  right: 0;
}
.containerMenuSlider {
  position: relative;
  display: flex;
  background-color: rgb(255, 255, 255);
  max-width: 880px;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 15px !important;
  /* right: 0; */
}
.menuDrow {
  display: none;
  width: 0px;
  height: 0px;
}
.iconSearch {
  fill: var(--cor-icone-buscar-navbar-CM);
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 1500px) {
  .containerMenuSlider {
    max-width: 880px;
  }
  .groupAcessibilidade {
    width: 880px;
  }
  .navLogoCM {
    width: 140px;
  }
  h5.titleNavbarCM {
    font-size: 1.1rem;
  }
  h3.titleNavbarCM {
    font-size: 1.4rem;
  }
  .navbar_navNameCM__mNh3N {
    align-items: center;
    width: 57%;
  }
}

@media screen and (max-width: 1400px) {
  .containerMenuSlider {
    max-width: 660px;
  }
  .groupAcessibilidade {
    width: 660px;
  }
}

@media screen and (max-width: 1300px) {
  .containerMenuSlider {
    max-width: 590px;
  }
  .groupAcessibilidade {
    width: 440px;
  }
  .inputSearch {
    width: 54%;
    padding: 5px;
  }
  .iconeAcessibilidade {
    width: 26px;
    height: 26px;
    border-radius: 26px;
  }
  .svgSearch {
    width: 14px;
    height: 14px;
  }
  /* .buttonSearch{
        padding: 7px;
       } */
}

@media screen and (max-width: 970px) {
  .cardLogo {
    flex-direction: column;
  }
}
@media screen and (max-width: 845px) {
  .containerMenuSlider {
    max-width: 220px;
  }
  .groupAcessibilidade {
    width: 100px;
    flex-direction: column;
    padding: 29px 0px 29px 0px;
  }
  .cardSearch {
    display: flex;
    width: 261px;
    justify-content: center;
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 600px) {
  .navbarCM {
    padding-bottom: 0;
    margin: 0px;
  }
  .navbarCM {
    background-color: #ffffff;
    align-items: center;
  }
  .subColumn {
    background-color: #ffffff;
    align-items: flex-end;
    margin-right: 17px;
  }
  .groupMenu {
    display: none;
  }
  .groupAcessibilidade {
    display: none;
  }
  .menuDrow {
    width: 36px;
    display: block;
    height: 36px;
    fill: var(--cor-icone-navbar-phone);
  }
  .navLogoCM {
    width: 70px;
  }
  h5.titleNavbarCM {
    font-size: 0.8rem;
  }
  h3.titleNavbarCM {
    font-size: 1rem;
  }
}
