/* .main-footer { */
/* bottom: 0; */
/* margin-top: 0rem;
  position: absolute;
  width: 100%; */
/* background-image: url("../../public/imagens/WORKCENTER_rodape.png");
  /* background-position-y: -224px; */
/* background-repeat: no-repeat; */
/* background-position: center; */
/* } */
/* .footer {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  width: 100%;
  padding: 0.3rem;
}
.footer h2 {
  font-size: 0.87rem;
} */
/* .footer li {
  font-size: 0.8rem;
  font-size: 0.8rem;
  display: flex;
  align-items: stretch;
  padding: 0.2rem;
}
.footer ul {
  list-style: none; */
/* Mudar de cor aqui dependendo do fundo*/
/* color: rgb(0, 0, 0);
  box-sizing: 2px 2px rgb(0, 0, 0);
  margin: 0;
} */
/* 
.footer a {
  text-decoration: none;
}

.div-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1390px;
}
.list {
  margin: 2px;
  min-width: 260px;
} */

/* @media screen and (max-width: 1115px) {
  .div-list {
    text-align: center;
    justify-content: center;
    gap: 10px;
  }
  .div-list li {
    display: flex;
    justify-content: center;
    gap: 5px;
    font: 10px;
  }
} */

/* .list:last-child { */

/* .footer-div { */
/* Mudar de cor aqui dependendo do fundo*/
/* border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.7);
} */
/* .footer h2 {
  font-weight: bold;
  margin-top: 0;
}
.svg-footer {
  width: 23px;
  padding-right: 0.7%;
}
.link-end-footer {
  text-align: center;
  padding-top: 13px;
}
.card-external {
  display: flex;
  justify-content: center;
  width: 100px;
  margin: 0 auto;
}
.card-social {
  display: flex;
  justify-content: space-around;
  width: 200px;
  gap: 15px;
  padding-right: 2px;
} */

/* .link-social {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.svg-footer-social {
  width: 21px;
}
.list-social {
  display: flex;
  justify-content: center;
}
.list-social {
  list-style: none;
} */

/* .link-end-footer a { */
/* display: flex; */
/* justify-content: center; */
/* text-decoration: none;
  color: #000000; */
/* font-size: 80%; */
/* }
.link-end-footer:hover a {
  color: #444444;
}

.list-footer {
  padding-bottom: 0.3em;
} */
/* @media screen and (max-width: 755px) { */
/* 
  .main-footer{
    margin-top: 2rem;
  } */
/* } */

/* @media screen and (max-width: 400px) {
  .svg-footer {
    width: 5%;
    height: 5%;
  } */
/* .svg-footer-social {
        width: 7%;
        height: 7%;
      } */
/* .footer h2 {
    font-size: 12px;
  }
  .footer li {
    font-size: 12px;
  }
  .link-end-footer a {
    font-size: 12px;
  } */
/* .main-footer{
          margin-top: 2rem;
        } */
/* } */
.main-footer img {
  width: 14px;
}

.main-footer {
  padding-top: 20px;
  background-color: var(--cor-diferenca-portal);
  box-shadow: -1px -2px 12px 1px #22222259;
  border-top: 0.5px solid #e0e0e09d;
  font-family: "Inter";
}

.footer {
  width: 100%;
  padding: 5px 14px;
}

.footer a:hover {
  text-decoration: underline;
  color: #000080;
}
/* REDES SOCIAIS */
.card-social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 2px;
}

.card-social a {
  color: var(--cor-texto-footer);
}

.link-social {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

/* DIREITOS AUTORAIS */
.link-end-footer {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--cor-texto-footer-desc);
}

.link-end-footer a {
  color: var(--cor-texto-footer-desc);
}
/*  */

.div-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 5px 10px;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.list h2 {
  font-size: 18px;
  color: var(--cor-texto-footer);
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
}

.list h2 a {
  color: var(--cor-texto-footer);
  font-size: 10px;
  text-decoration: underline;
  font-weight: 500;
}

.list li {
  color: var(--cor-texto-footer-desc);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px 6px;
  align-items: center;
  justify-content: flex-start;
  word-wrap: initial;
  word-break: keep-all;
}

@media screen and (max-width: 1210px) {
  .div-list {
    flex-wrap: wrap;
    gap: 20px 15px;
  }
  .list {
    justify-content: center;
    align-items: center;
  }
  .card-social {
    margin-top: 20px;
    margin-bottom: 22px;
  }
  .link-end-footer {
    text-align: center;
  }
}
